@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;1,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-size: 100%;
  background-repeat: no-repeat;
  background-color: black !important;
  /* background-image: url(./assets/background.jpg); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
 * .page is the base node within the JavaScript application. It is the direct child of .root,
 * whichlives outside of JavaScript. The .page has a Header and a .container.
 */
/*
.page {
}
*/

.white-row {
  background-color: rgba(255, 255, 255, 0.8);
  color: #222;
}

.white-row-first {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 1rem;
}

.white-row-last {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 0;
}

/* .button-login {

  background-color: #e8f4a9;
  color:#000000;
  
} */
/* .button-login:hover {

  
} */

.network-badge{
  background-color: #e8f4a9;
  color:#000000;
  padding: 0.5rem;

}
  .breadcrumb {
    background-color: rgb(35, 38, 39);
  }
  .list-group-item {
  background-color: rgb(24, 26, 27);
  border-color: rgba(140, 130, 115, 0.13);
  }
  .card {
    background-color: rgb(24, 26, 27);
    border-color: rgba(140, 130, 115, 0.13);
  }
  .form-control {
    color: rgb(181, 175, 166);
    background-color: rgb(24, 26, 27);
    border-color: rgb(60, 65, 68);
  }
  
  .table {
    color: rgb(209, 205, 199);
  }
  .table thead th {
    border-bottom-color: rgb(120, 112, 99);
  }
  .table td, .table th {
    border-top-color: rgb(120, 112, 99);
  }
  .breadcrumb-item.active {
    color: rgb(158, 150, 137);
  }
  a {
    color: rgb(51, 162, 255);
    text-decoration-color: initial;
    background-color: transparent;
  }
  a:hover {
    color: rgb(97, 183, 255);
    text-decoration-color: initial;
  }
  .bg-white {
    background-color: rgb(24, 26, 27) !important;
  }
  .btn-light {
    color: rgb(200, 195, 188);
    background-color: rgb(22, 24, 25);
    border-color: rgb(123, 114, 101);
  }
  a.code-content {
    background-color: rgba(24, 26, 27, 0.8) !important;
    color: rgb(211, 207, 201) !important;
  }
  a.code-content:hover {
    background-color: rgba(24, 26, 27, 0.9) !important;
  }
  .dropdown-menu {
    color: rgb(209, 205, 199);
    list-style-image: initial;
    background-color: rgb(24, 26, 27);
    border-color: rgba(140, 130, 115, 0.15);
  }
  .dropdown-item {
    color: rgb(209, 205, 199);
    background-color: transparent;
    border-color: initial;
  }
  .dropdown-item:focus, .dropdown-item:hover {
    color: rgb(217, 213, 208);
    text-decoration-color: initial;
    background-color: rgb(27, 30, 31);
  }
  pre {
    color: rgb(209, 205, 199);
  }
  body {
    color: rgb(209, 205, 199);
  }
  
  .white-row {
    background-color: rgba(24, 26, 27, 0.8) !important;
    color: rgb(201, 196, 189) !important;
  }
  
* {
  font-family: "inter";
}

.main-navbar {
  background: transparent !important;
  padding: 0.5rem 7.5rem;
}

.site-title {
  color: #fa8072 !important;
  font-weight: bold;
}

.login-container {
  margin-top: 1rem;
  padding-left: 1rem;
}

.network-badge {
  margin-right: 1rem;
}

.nav-link:hover {
  background-color: rgb(24 24 27/1);

}

.navbar-brand:hover {
  filter: drop-shadow(0 0 8px #ec4899) ;
  transform: scale(1.05);
}

@media only screen and (max-width: 600px) {
  .main-navbar {
    padding:8px 20px;
  }
}
.ctr-column {
  max-width: 18rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  clear: both;
}

.file {
    margin-left: 20px;
}

input[type="file"] {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
}


.long-inline-code {
  overflow-wrap: break-word;
  word-break: break-all;
}


/* See https://stackoverflow.com/a/51775832/2013738 */
.flex-element-two-two {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
}

a.code-content {
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #222;
  display: block;
  height: 100%;
}

a.code-content:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.code-content .id {
  font-size: 30px;
  width: 70px;
  float: left;
  text-align: center;
  margin-right: 10px;
}

.code-content .details {
  display: inline-block;
}

.code-package {
  color: cadetblue;
}

