@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;1,600&display=swap');

* {
  font-family: "inter";
}

.main-navbar {
  background: transparent !important;
  padding: 0.5rem 7.5rem;
}

.site-title {
  color: #fa8072 !important;
  font-weight: bold;
}

.login-container {
  margin-top: 1rem;
  padding-left: 1rem;
}

.network-badge {
  margin-right: 1rem;
}

.nav-link:hover {
  background-color: rgb(24 24 27/1);

}

.navbar-brand:hover {
  filter: drop-shadow(0 0 8px #ec4899) ;
  transform: scale(1.05);
}

@media only screen and (max-width: 600px) {
  .main-navbar {
    padding:8px 20px;
  }
}