.file {
    margin-left: 20px;
}

input[type="file"] {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
}